const _ = require('lodash')
const moment = require('moment')
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Col } from 'react-bootstrap'
import NotificationAlert from 'react-notification-alert'

import { getData, postData } from 'utils/server'
import { getAuthorizationHeader, notificationOptions } from 'utils/helpers'
import SportSelect from 'components/Inputs/SportSelect'
import RegionSelect from 'components/Inputs/RegionSelect'
import CustomSelect from 'components/Inputs/CustomSelect'
import StartDateSelect from 'components/Inputs/StartDateTimeSelect'
import Participants from 'views/Participants'

function CreateUniFixture({ showModal, signalCloseModal, copiedFixture }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)

  const [show, setShow] = useState(false)
  const [uniSports, setUniSports] = useState([])
  const [uniRegions, setUniRegions] = useState([])
  const [uniCompetitions, setUniCompetitions] = useState([])
  const [uniParticipants, setUniParticipants] = useState([])

  const [filteredUniSports, setFilteredUniSports] = useState([])
  const [filteredUniRegions, setFilteredUniRegions] = useState([])
  const [filteredUniCompetitions, setFilteredUniCompetitions] = useState([])
  const [filteredUniParticipants, setFilteredUniParticipants] = useState([])

  const [selectedSport, setSelectedSport] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)
  const [selectedCompetition, setSelectedCompetition] = useState(null)
  const [selectedParticipantHome, setSelectedParticipantHome] = useState(null)
  const [selectedParticipantAway, setSelectedParticipantAway] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [disableCreation, setDisableCreation] = useState(true)
  const [errorMessageShow, setErrorMessageShow] = useState(false)

  const fetchData = async controller => {
    //console.log('fetchData CheckPoint: disableCreation:', disableCreation)
    console.log('init Data')
    //console.log('copiedFixture->', copiedFixture)
    setSelectedSport(null)
    setSelectedRegion(null)
    setSelectedCompetition(null)
    setSelectedParticipantHome(null)
    setSelectedParticipantAway(null)
    setStartDate(null)
    setDisableCreation(copiedFixture ? false : true)

    await fetchSports(controller)
    await fetchRegions(controller)
    await fetchCompetitions(controller)
    await fetchParticipants(controller)
    setShow(showModal)
  }

  const fetchSports = async controller => {
    let response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniSports(response.data)
    }
    if (copiedFixture) {
      let copiedFixtureUniSport = response.data.find(sport => sport.name == copiedFixture.sportName)
      if (copiedFixtureUniSport) {
        setSelectedSport(copiedFixtureUniSport)
        copiedFixture.sportFound = true
      }
    }
    //console.log('fetchSports CheckPoint: disableCreation:', disableCreation)
    return true
  }

  const fetchRegions = async controller => {
    let response = await getData('/api/unis/regions', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      setUniRegions(response.data)
    }
    if (copiedFixture) {
      let copiedFixtureUniRegion = response.data.find(region => region.name == copiedFixture.region)
      if (copiedFixtureUniRegion) {
        setSelectedRegion(copiedFixtureUniRegion)
        copiedFixture.regionFound = true
        setDisableCreation(copiedFixture ? false : false)
      }
    }
    //setUpdateUniComps(true)
    //console.log('fetchRegions CheckPoint: disableCreation:', disableCreation)
    return true
  }

  const fetchCompetitions = async controller => {
    let response = await getData('/api/unis/competitions', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data.map(item => {
        item.label = item.name
      })
      setUniCompetitions(response.data)
    }
    if (copiedFixture) {
      let copiedFixtureUniCompetition = response.data.find(competition => competition.name == copiedFixture.competition && competition.sport == copiedFixture.sportName && competition.region == copiedFixture.region)
      if (copiedFixtureUniCompetition) setSelectedCompetition(copiedFixtureUniCompetition)
    }

    //console.log('fetchCompetitions CheckPoint1: disableCreation:', disableCreation)

    await filterCompetitions()

    //console.log('fetchCompetitions CheckPoint2: disableCreation:', disableCreation)
    return true
    //setUpdateUniComps(true)
  }

  const fetchParticipants = async controller => {
    setDisableCreation(copiedFixture ? false : false)
    let response = await getData('/api/unis/participants', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null) {
      response.data.map(item => {
        item.label = item.name
      })
      setUniParticipants(response.data)
    }
    if (copiedFixture) {
      let copiedFixtureUniPartHome = response.data.find(participant => participant.name == copiedFixture.home && participant.sport == copiedFixture.sportName)
      if (copiedFixtureUniPartHome) setSelectedParticipantHome(copiedFixtureUniPartHome)
      let copiedFixtureUniPartAway = response.data.find(participant => participant.name == copiedFixture.away && participant.sport == copiedFixture.sportName)
      if (copiedFixtureUniPartAway) setSelectedParticipantAway(copiedFixtureUniPartAway)
    }
    await filterParticipants(response.data)
    //setUpdateUniComps(true)
    return true
  }

  const filterCompetitions = async () => {
    setDisableCreation(copiedFixture ? false : false)
    let filtered_competitions = JSON.parse(JSON.stringify(uniCompetitions))

    if (selectedSport) {
      filtered_competitions = filtered_competitions.filter(comp => comp.sport == selectedSport.label)
    }

    if (selectedRegion) {
      filtered_competitions = filtered_competitions.filter(comp => comp.region == selectedRegion.label)
    } else {
      if (copiedFixture) {
        filtered_competitions = filtered_competitions.filter(comp => comp.region == copiedFixture.region)
      }
    }

    if (filtered_competitions.length === 0) {
      setDisableCreation(copiedFixture ? false : true)
      setSelectedCompetition(null)
    } else {
      if (selectedRegion && !filtered_competitions.find(comp => comp.id == selectedRegion.id)) setSelectedCompetition(null)
      if (!selectedRegion) {
        setDisableCreation(copiedFixture ? false : true)
        //setSelectedCompetition(null)
      } else {
        setDisableCreation(copiedFixture ? false : false)
      }
    }

    setFilteredUniCompetitions(filtered_competitions)
    return true
  }

  const filterParticipants = data => {
    let filtered_participants = JSON.parse(JSON.stringify(uniParticipants))
    if (data) filtered_participants = JSON.parse(JSON.stringify(data))

    if (selectedSport) {
      let sportName = selectedSport.label ? selectedSport.label : selectedSport.name ? selectedSport.name : null
      filtered_participants = sportName ? filtered_participants.filter(part => part.sport == sportName) : filtered_participants
    } else {
      if (copiedFixture) {
        let sportName = copiedFixture.sportName ? copiedFixture.sportName : null
        filtered_participants = sportName ? filtered_participants.filter(part => part.sport == sportName) : filtered_participants
      }
    }

    if (filtered_participants.length === 0) {
      setDisableCreation(copiedFixture ? false : true)
      setSelectedParticipantHome(null)
      setSelectedParticipantAway(null)
    } else {
      if (selectedParticipantAway && !filtered_participants.find(part => part.id == selectedParticipantAway.id)) setSelectedParticipantAway(null)
      if (selectedParticipantHome && !filtered_participants.find(part => part.id == selectedParticipantHome.id)) setSelectedParticipantHome(null)
      if (!selectedRegion) {
        setDisableCreation(copiedFixture ? false : true)
        //setSelectedParticipantHome(null)
        //setSelectedParticipantAway(null)
      } else {
        setDisableCreation(copiedFixture ? false : false)
      }
    }

    setFilteredUniParticipants(filtered_participants)
    return true
  }

  const handleClose = () => {
    setShow(false)
    copiedFixture = null
    setSelectedSport(null)
    setSelectedRegion(null)
    setSelectedCompetition(null)
    setSelectedParticipantHome(null)
    setSelectedParticipantAway(null)
    setStartDate(null)
    setDisableCreation(true)
    signalCloseModal(false)
  }

  const handleSubmit = async () => {
    if (_.isEmpty(selectedSport) || _.isEmpty(selectedRegion) || _.isEmpty(selectedCompetition) || _.isEmpty(selectedParticipantHome) || _.isEmpty(selectedParticipantAway) || selectedCompetition.id === -1 || selectedParticipantHome.id === -1 || selectedParticipantAway.id === -1) {
      let errorMessage = 'Error! Cannot add Fixture.'
      let errors = []

      if (_.isEmpty(selectedSport)) errors.push('Sport Missing!')
      if (_.isEmpty(selectedRegion)) errors.push('Region Missing!')
      if (_.isEmpty(selectedCompetition)) errors.push('Competition Missing!')
      if (_.isEmpty(selectedParticipantHome)) errors.push('Home Missing!')
      if (_.isEmpty(selectedParticipantAway)) errors.push('Away Missing!')

      notificationAlertRef.current.notificationAlert(
        notificationOptions(
          <div>
            <div>{errorMessage}</div>
            <ul>
              {errors.map(item => {
                return (
                  <li>
                    <b>{item}</b>
                  </li>
                )
              })}
            </ul>
          </div>,
          'danger'
        )
      )
      return
    }

    const fixture = {
      startDate: startDate.getTime(),
      sport: selectedSport.label,
      sport_id: selectedSport.id,
      region: selectedRegion.label,
      region_id: selectedRegion.id,
      competition: selectedCompetition.label,
      competition_id: selectedCompetition.id,
      home: selectedParticipantHome ? selectedParticipantHome.label : null,
      home_id: selectedParticipantHome ? selectedParticipantHome.id : null,
      away: selectedParticipantAway ? selectedParticipantAway.label : null,
      away_id: selectedParticipantAway ? selectedParticipantAway.id : null
    }

    let response = await postData(`/api/unis/fixtures`, fixture, { Authorization: getAuthorizationHeader() })
    if (response == null) {
      notificationAlertRef.current.notificationAlert(notificationOptions('Error! Cannot add Fixture.', 'danger'))
      return
    }
    notificationAlertRef.current.notificationAlert(notificationOptions('Success! Fixture Added.', 'success'))
    setShow(false)
    signalCloseModal(true)
  }

  const updateSelectedSport = selSport => {
    setSelectedSport(selSport)
    // filterCompetitions()
    // filterParticipants()
  }

  const updateSelectedRegions = selRegion => {
    setSelectedRegion(selRegion)
    // filterCompetitions()
    // filterParticipants()
  }

  const updateSelectedCompetitions = selCompetition => {
    setSelectedCompetition(selCompetition)
    // filterParticipants()
  }

  const updateSelectedHome = participant => {
    setSelectedParticipantHome(participant)
  }

  const updateSelectedAway = participant => {
    setSelectedParticipantAway(participant)
  }

  const updateUnformattedDate = startDate => {
    setStartDate(startDate)
  }

  useEffect(() => {
    if (showModal) fetchData(controller)
  }, [showModal])

  useEffect(() => {
    if (selectedSport) filterCompetitions()
    if (selectedSport) filterParticipants()
  }, [selectedSport])

  useEffect(() => {
    if (selectedRegion) filterCompetitions()
  }, [selectedRegion])

  useEffect(() => {
    if (disableCreation) {
      setErrorMessageShow(true)
    } else {
      setErrorMessageShow(false)
    }
  }, [disableCreation])

  useEffect(() => {
    if (copiedFixture) {
      //console.log('CreateUniFixture->useEffect->', copiedFixture)
      filterCompetitions()
      filterParticipants()

      // setSelectedSport({ id: copiedFixture.sportId, value: copiedFixture.sportId, label: copiedFixture.sportName, name: copiedFixture.sportName })
      // setSelectedRegion({ id: copiedFixture.regionId, value: copiedFixture.regionId, label: copiedFixture.region, name: copiedFixture.region })
    }
  }, [copiedFixture])

  useEffect(() => {
    return () => {
      setShow(false)
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Modal backdropClassName="create-uni-fixture-backdrop" show={show} onHide={handleClose} className="create-uni-fixture-modal">
        <Modal.Header>Create Uni-Fixture</Modal.Header>
        <Modal.Body>
          <label style={{ display: errorMessageShow ? '' : 'none', color: 'red', textAlign: 'right', width: '100%' }}> Sorry you cant Create Uni-Fixture with these selections</label>
          <Form onSubmit={handleSubmit} className="create-uni-fixture-form">
            <Col md="12" style={{ display: 'flex', marginBottom: '40px' }}>
              <Col md="4">
                <Form.Group>
                  <label>Select Sport</label>
                  <SportSelect sports={uniSports} updateSport={updateSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Select Region</label>
                  <RegionSelect regions={uniRegions} updateRegion={updateSelectedRegions} selectedItem={JSON.stringify(selectedRegion)} />
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group>
                  <label>Select Competition</label>
                  <CustomSelect options={filteredUniCompetitions} setName="Competition" updateSelection={updateSelectedCompetitions} selectedItem={JSON.stringify(selectedCompetition)} disabled={disableCreation} />
                </Form.Group>
              </Col>
            </Col>
            <Col md="12" style={{ display: 'flex', marginBottom: '40px' }}>
              <Col md="6">
                <Form.Group>
                  <label>Select Home</label>
                  <CustomSelect options={filteredUniParticipants} setName="Participant" updateSelection={updateSelectedHome} selectedItem={JSON.stringify(selectedParticipantHome)} disabled={disableCreation} />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Select Away</label>
                  <CustomSelect options={filteredUniParticipants} setName="Participant" updateSelection={updateSelectedAway} selectedItem={JSON.stringify(selectedParticipantAway)} disabled={disableCreation} />
                </Form.Group>
              </Col>
            </Col>
            <br />
            <Col md="12" style={{ display: 'flex', marginBottom: '40px' }}>
              <Col md="6">
                <Form.Group style={{ display: 'grid' }}>
                  <label>Select Date</label>
                  <StartDateSelect setParentUnformattedDate={updateUnformattedDate} disabled={disableCreation} initDate={copiedFixture ? copiedFixture.startDate : null} />
                </Form.Group>
              </Col>
            </Col>
            {/* <div className="clearfix"></div> */}
          </Form>
          <br />
        </Modal.Body>
        <Modal.Footer className="create-uni-fixture-footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={disableCreation} style={{ display: disableCreation ? 'none' : '' }}>
            Create Uni-Fixture
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CreateUniFixture
