import Dashboard from 'views/Dashboard.js'
import Logout from 'views/Logout.js'
import Regions from 'views/Regions.js'
import Competitions from 'views/Competitions.js'
import Participants from 'views/Participants.js'
import Players from 'views/Players'
import Fixtures from 'views/Fixtures.js'
import Users from 'views/Users.js'
import MarketGroups from 'views/MarketGroups.js'
import MarketSettlements from 'views/MarketSettlements'
import Languages from 'views/Languages.js'
import Translations from 'views/Translations.js'
import Operation from 'views/Operation.js'
import Markets from 'views/Markets.js'
import MarketsOrdering from 'views/MarketsOrdering.js'
import Matching from 'views/Matching.js'
import MatchCompetitions from 'views/MatchCompetitions.js'
import FixtureConflicts from 'views/FixtureConflicts.js'
import HighlightedFixtures from 'views/HighlightedFixtures.js'
import UserDashboard from 'views/UserDashboard'
import RecreatedUniFixtures from 'components/Tables/RecreatedUniFixtures'

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/userdashboard',
    name: 'User Dashboard',
    icon: 'nc-icon nc-chart-bar-32',
    component: UserDashboard,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/regions',
    name: 'Regions',
    icon: 'nc-icon nc-pin-3',
    component: Regions,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/competitions',
    name: 'Competitions',
    icon: 'nc-icon nc-bullet-list-67',
    component: Competitions,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/participants',
    name: 'Participants',
    icon: 'nc-icon nc-badge',
    component: Participants,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/players',
    name: 'Players',
    icon: 'nc-icon nc-badge',
    component: Players,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/fixtures-match',
    name: 'Fixtures',
    icon: 'nc-icon nc-cctv',
    component: Fixtures,
    layout: '/admin',
    allowOperators: true
  },
  // {
  //   path: '/fixtures-ext',
  //   name: 'Fixtures EXT',
  //   icon: 'nc-icon nc-cctv',
  //   component: FixturesExt,
  //   layout: '/admin',
  //   onlyAdmins: true,
  //   notTranslators: true
  // },
  {
    path: '/markets',
    name: 'Markets',
    icon: 'nc-icon nc-tag-content',
    component: Markets,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/market-ordering',
    name: 'Market Ordering',
    icon: 'nc-icon nc-tag-content',
    component: MarketsOrdering,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/market-groups',
    name: 'Market Groups',
    icon: 'nc-icon nc-tag-content',
    component: MarketGroups,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/market-settlement',
    name: 'Market Settlement',
    icon: 'nc-icon nc-tag-content',
    component: MarketSettlements,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/matching',
    name: 'Match Fixtures',
    icon: 'nc-icon nc-spaceship',
    component: Matching,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/match-competitions',
    name: 'Match Competitions',
    icon: 'nc-icon nc-spaceship',
    component: MatchCompetitions,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/fixture-conflicts',
    name: 'Fixture Conflicts',
    icon: 'nc-icon nc-settings-tool-66',
    component: FixtureConflicts,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/recreate-fixtures',
    name: 'Recreate Fixtures',
    icon: 'nc-icon nc-settings-90',
    component: RecreatedUniFixtures,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/highlighted-fixtures',
    name: 'Highlighted Events',
    icon: 'nc-icon nc-bulb-63',
    component: HighlightedFixtures,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/languages',
    name: 'Languages',
    icon: 'nc-icon nc-globe-2',
    component: Languages,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/translations',
    name: 'Translations',
    icon: 'nc-icon nc-caps-small',
    component: Translations,
    layout: '/admin',
    allowOperators: true
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'nc-icon nc-single-02',
    component: Users,
    layout: '/admin',
    allowOperators: false
  },
  {
    path: '/operation',
    name: 'Operation',
    icon: 'nc-icon nc-settings-gear-64',
    component: Operation,
    layout: '/admin',
    allowOperators: true
  },
  {
    upgrade: true,
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-key-25',
    component: Logout,
    layout: '/admin',
    allowOperators: true
  },
  {
    upgrade: true,
    path: '/logout',
    name: 'Logout',
    icon: 'nc-icon nc-key-25',
    component: Logout,
    layout: '/client',
    allowOperators: true
  }
]

export default dashboardRoutes
