const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import NotificationAlert from 'react-notification-alert'
import stringSimilarity from 'string-similarity'

import Swal from 'sweetalert2'

import { postData, getData, delData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'
import TableCounters from 'components/UniObject/TableCounters'
import { setDefaultLocale } from 'react-datepicker'

function UniFixtures({ informParent, providerId, startDate, sportId, selectedEvent, defaultSimilarity, defaultMatchingSectors, selectOptions, forceUpdate, downloadcsv }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [allcounter, setAllCounter] = useState(0)
  const [filteredcounter, setFilteredcounter] = useState(0)
  const [bestMatch, setBestMatch] = useState([])
  const [eventSimilarity, setEventSimilarity] = useState(0)
  const [checkSimilarity, setDefaultSimilarity] = useState(0.5)
  const [regionSector, setRegionSector] = useState(false)
  const [competitionSector, setCompetitionSector] = useState(false)
  const [participantSector, setParticipantSector] = useState(true)

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '25',
        value: 20
      },
      {
        text: '50',
        value: 50
      }
    ]
  }

  const DeleteButton = props => {
    return props.item.created_from_provider_id === 0 ? (
      <Button
        className="btn-simple btn-link p-1"
        type="button"
        variant={props.item.created_from_provider_id == 0 ? 'danger' : 'secondary'}
        disabled={props.item.created_from_provider_id == 0 ? false : true}
        onClick={() => {
          handleDeleteUniFixture(props.item)
        }}
      >
        <i className="fas fa-times"></i>
      </Button>
    ) : (
      ''
    )
  }

  const InfoButton = props => {
    return props.item.matchedevents.length > 0 ? (
      <Button
        className="btn-simple btn-link p-1"
        type="button"
        variant={props.item.matchedevents.length > 0 ? 'dark' : 'secondary'}
        disabled={props.item.matchedevents.length > 0 ? false : true}
        onClick={() => {
          handleMatchingInfoUniFixture(props.item)
        }}
        title={'Matched Events: ' + props.item.matchedevents.join(',')}
      >
        <i className="fas fa fa-info"></i>
      </Button>
    ) : (
      ''
    )
  }

  const ShowLinkedButton = props => {
    return props.item.linkedevents.length > 0 ? (
      <Button
        className="btn-simple btn-link p-1"
        type="button"
        variant="primary"
        onClick={() => {
          handleLinkedInfoUniFixture(props.item)
        }}
        title={'Linked Uni Fixtures: ' + props.item.linkedevents.join(',')}
      >
        <i className='fas fa-link'></i>
      </Button>
    ) : (
      ''
    )
  }

  const selectRow = {
    clickToSelect: false,
    mode: 'radio',
    style: { backgroundColor: selectOptions.color },
    onSelect: ev => {
      setBestMatch([ev.eventId])
      selectOptions.handler(ev)
    },
    selected: bestMatch
  }

  const columns = [
    {
      dataField: 'eventId',
      text: 'Event ID',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'region',
      text: 'Region',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'competition',
      text: 'Competition',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'home',
      text: 'Home',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'away',
      text: 'Away',
      filter: textFilter(),
      sort: true
    },
    {
      dataField: 'timestamp',
      text: 'Start Date',
      sort: true
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '',
      editable: false,
      headerClasses: 'delete-column',
      formatter: (cellContent, row) => (
        <>
          <InfoButton item={row} />
          <ShowLinkedButton item={row} />
          <DeleteButton item={row} />
        </>
      )
    }
  ]

  const fetchData = async controller => {
    if (providerId == null || startDate == null || sportId == null) return
    let filters = {
      sport_id: sportId,
      start_date: startDate
    }
    let uri = '/api/unis/fetchFixtures'
    let response = await postData(uri, filters, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response == null) return
    let tableData = []
    for (let fixture of response.data) {
      let jsDate = new Date(parseInt(fixture.start_date))
      fixture.eventId = fixture.id
      fixture.timestamp = jsDate.toLocaleString('en-US').split(',')[1]
      tableData.push(fixture)
    }
    setAllCounter(response.data.length)
    setData(tableData)
    informParent({ downloadcsv: true })
  }

  const downloadCsv = async controller => {
    let csvData = _.sortBy(tableData, ['region', 'competition'])
    let csv = 'data:text/csv;charset=utf-8,'
    csv += 'Region,Competition,Home,Away,Start Date\n'
    csvData.forEach(row => {
      csv += `${row.region},${row.competition},${row.home},${row.away},${row.timestamp}\n`
    })
    let encodedUri = encodeURI(csv)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'fixtures.csv')
    document.body.appendChild(link)
    link.click()
    informParent({ downloadcsv: true })
  }

  const handleMatchingInfoUniFixture = async fixture => {
    let result = await getData(`/api/unis/fixtures/${fixture.id}/relations`, { Authorization: getAuthorizationHeader() })

    console.log(result.data)

    let htmlText = '<div style="min-width:150px;text-align: left;"><ul>'

    let eventId_width = 150
    let region_width = 100
    let competition_width = 100
    let participants_width = 100

    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index]
      eventId_width = eventId_width < element.fixture.eventId.toString().length * 10 ? element.fixture.eventId.toString().length * 10 : eventId_width
      region_width = region_width < element.fixture.region.length * 10 ? element.fixture.region.length * 10 : region_width
      competition_width = competition_width < element.fixture.competition.length * 10 ? element.fixture.competition.length * 10 : competition_width
      participants_width = participants_width < (element.fixture.participants[0].name + ' Vs ' + element.fixture.participants[1].name).length * 10 ? (element.fixture.participants[0].name + ' Vs ' + element.fixture.participants[1].name).length * 10 : participants_width
    }

    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index]
      htmlText =
        htmlText +
        `<li style="border-bottom: 1px solid;width: max-content;">
          <div style="display: flex;gap: 5px;">
            <div class="${element.provider}Provider" style="text-align: center;margin-bottom: auto;margin-top: auto;">${element.provider}</div>
            <div class="event_id" style="width: ${eventId_width}px">${element.fixture.eventId}</div>
            <div class="event_region" style="width: ${region_width}px">${element.fixture.region}</div>
            <div class="event_competition" style="width: ${competition_width}px">${element.fixture.competition}</div>
            <div class="event_participants" style="width: ${participants_width}px">${element.fixture.participants[0].name} Vs ${element.fixture.participants[1].name}</div>
          </div>
        </li>`
    }

    htmlText = htmlText + '</ul></div>'

    Swal.fire({ 
      title: `Related Fixtures:`, 
      customClass: 'swal-wide', 
      html: htmlText, 
      showDenyButton: false, 
      showConfirmButton: true, 
      confirmButtonText: 'OK', 
      denyButtonText: 'Cancel', 
      icon: 'info', 
      confirmButtonColor: '#87cb16', 
      denyButtonColor: '#ff4a55' }).then(async result => {
        if (result.isConfirmed) {
      }
    })
  }

  const handleLinkedInfoUniFixture = async fixture => {
    let response = await getData(`/api/unis/fixtures/filtered/${fixture.linkedevents.toString()}`, { Authorization: getAuthorizationHeader() })
    let result = response.data

    for (let fixture of result) {
      let jsDate = new Date(parseInt(fixture.start_date))
      fixture.eventId = fixture.id
      fixture.timestamp = jsDate.toLocaleString('en-US').split(',')[1]
    }
    
    let htmlText = `
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Event Id</th>
            <th>Region</th>
            <th>Competition</th>
            <th>Home</th>
            <th>Away</th>
            <th>Starts At</th>
          </tr>
        </thead>
        <tbody>
    `
    for (let linked of result) {
      htmlText += `
        <tr key="${linked.id}">
          <td>${linked.id}</td>
          <td>${linked.region}</td>
          <td>${linked.competition}</td>
          <td>${linked.home}</td>
          <td>${linked.away}</td>
          <td>${linked.timestamp}</td>
        </tr>
      `
    }
    htmlText += `
        </tbody>
      </table>
    `

    Swal.fire({ 
      title: `Linked Fixtures:`, 
      customClass: 'swal-wide', 
      html: htmlText, 
      showDenyButton: false, 
      showConfirmButton: true, 
      confirmButtonText: 'OK', 
      denyButtonText: 'Cancel', 
      icon: 'info', 
      confirmButtonColor: '#87cb16', 
      denyButtonColor: '#ff4a55' }).then(async result => {
        if (result.isConfirmed) {
      }
    })
  }

  const handleDeleteUniFixture = fixture => {
    Swal.fire({ title: `Delete Fixture <br><br> ${fixture.home} Vs ${fixture.away}? <br>`, showDenyButton: true, showConfirmButton: true, confirmButtonText: 'Yes, delete it!', denyButtonText: 'Cancel', icon: 'warning', confirmButtonColor: '#87cb16', denyButtonColor: '#ff4a55' }).then(async result => {
      if (result.isConfirmed) {
        let result = await delData(`/api/unis/fixtures/${fixture.id}`, { Authorization: getAuthorizationHeader() })
        if (result != null) {
          Swal.fire('Deleted!', `Fixture <br><br> ${fixture.home} Vs ${fixture.away} <br><br> has been deleted.`, 'success')
          fetchData(controller)
        } else {
          Swal.fire('Error!', `Something went wrong.`, 'error')
        }
      }
    })
  }

  function afterFilter(newResult, newFilters) {
    setFilteredcounter(newResult.length)
  }

  const searchForBestMatch = event => {
    const minSimilarity = checkSimilarity
    const eventToSearch = createString(event) //`${event.region}::${event.competition}::${event.home}::${event.away}`
    console.log('eventToSearch: ', eventToSearch)
    const eventIds = []
    let matches = []

    for (let index = 0; index < tableData.length; index++) {
      console.log(index)
      const fixture = tableData[index]
      const uniFixtureString = createString(fixture) //`${fixture.region}::${fixture.competition}::${fixture.home}::${fixture.away}`
      let similarity = stringSimilarity.compareTwoStrings(eventToSearch, uniFixtureString)
      console.log('uniFixtureString: ', uniFixtureString, ', similarity: ', similarity)
      if (similarity > minSimilarity) {
        eventIds.push(fixture.eventId)
        matches.push(uniFixtureString)
        console.log('eventFound: ', uniFixtureString, ', similarity: ', similarity)
      }
    }

    if (matches.length > 0) {
      let bestMatch = stringSimilarity.findBestMatch(eventToSearch, matches)
      let index = matches.indexOf(bestMatch.bestMatch.target)
      let ev = tableData.filter(item => item.eventId == eventIds[index])
      setBestMatch([eventIds[index]])
      console.log('BEST MATCH IS: ', bestMatch)
      setEventSimilarity(bestMatch.ratings[0].rating)
      selectOptions.handler(ev[0])
    } else {
      setBestMatch([])
      setEventSimilarity(0)
    }
  }

  const createString = event => {
    let stringToCheck = ''

    if (regionSector) stringToCheck = event.region
    if (competitionSector) stringToCheck = stringToCheck == '' ? event.competition : `${stringToCheck}::${event.competition}`
    if (participantSector) stringToCheck = stringToCheck == '' ? `${event.home}::${event.away}` : `${stringToCheck}::${event.home}::${event.away}`

    return stringToCheck
  }

  const setMatchingSectors = sectors => {
    console.log('sectors Changed')
    setRegionSector(sectors.rSector)
    setCompetitionSector(sectors.cSector)
    setParticipantSector(sectors.pSector)
  }

  useEffect(() => {
    fetchData(controller)
  }, [providerId, startDate, sportId, forceUpdate])

  useEffect(() => {
    if (selectedEvent) searchForBestMatch(selectedEvent)
  }, [selectedEvent])

  useEffect(() => {
    if (downloadcsv) downloadCsv(controller)
  }, [downloadcsv])

  useEffect(() => {
    if (defaultSimilarity) setDefaultSimilarity(defaultSimilarity)
  }, [defaultSimilarity])

  useEffect(() => {
    if (defaultMatchingSectors) setMatchingSectors(defaultMatchingSectors)
  }, [defaultMatchingSectors])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <TableCounters all={allcounter} filtered={filteredcounter}></TableCounters>
      <div>
        matched rating: <span style={{ color: eventSimilarity > 0.8 ? 'green' : 'orange' }}>{(eventSimilarity * 100).toFixed(0)}%</span>
      </div>
      <BootstrapTable loading={true} bootstrap4 keyField="eventId" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} filter={filterFactory({ afterFilter })} selectRow={selectRow} />
    </>
  )
}

export default UniFixtures
