const _ = require('lodash')
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import NotificationAlert from 'react-notification-alert'

import UpdateProviderLanguages from 'components/Modals/UpdateProviderLanguages'

import Swal from 'sweetalert2'

import { getData } from 'utils/server'
import { getAuthorizationHeader, getUserData } from 'utils/helpers'

function LanguagesTable({ forceUpdate }) {
  const controller = new AbortController()

  const notificationAlertRef = React.useRef(null)
  const [tableData, setData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [langId, setLangId] = useState(null)
  const [selectedLang, setselectedLang] = useState(null)

  const paginationOptions = {
    disablePageTitle: true,
    showTotal: false,
    sizePerPageList: [
      {
        text: '10',
        value: 10
      }
    ]
  }

  const handleEditClick = row => {
    setLangId(row.id)
    setselectedLang(row.name)
    showModal ? setShowModal(false) : setShowModal(true)
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID'
    },
    {
      dataField: 'name',
      text: 'Name',
      editable: false
    },
    {
      dataField: 'code',
      text: 'ISO Code',
      editable: false
    },
    {
      dataField: 'dm1',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => (
        <>
          <Button
            className="btn-simple btn-link p-1"
            type="button"
            variant="success"
            onClick={() => {
              handleEditClick(row)
            }}
          >
            <i className="fas fa-download"></i>
          </Button>
        </>
      )
    }
  ]

  const fetchData = async controller => {
    let response = await getData(`/api/languages`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    let tableData = []
    if (response != null) tableData = response.data
    setData(tableData)
  }
  const handleCloseModal = updt => {
    setShowModal(false)
    if (updt) fetchData(controller)
  }
  useEffect(() => {
    fetchData(controller)
  }, [forceUpdate])

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  return (
    <>
      <UpdateProviderLanguages showModal={showModal} langId={langId} lang={selectedLang} signalCloseModal={handleCloseModal} />
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <BootstrapTable bootstrap4 keyField="id" data={tableData} columns={columns} pagination={paginationFactory({ sizePerPage: 10 })} />
    </>
  )
}
export default LanguagesTable
