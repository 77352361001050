import React, { useState, useEffect } from 'react'

import { Chart } from 'chart.js/auto'

import { getData, getConstantData } from 'utils/server'
import { getAuthorizationHeader } from 'utils/helpers'

import SportSelect from 'components/Inputs/SportSelect'
import DataSelect from 'components/Inputs/generalDataSelect'

// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form, OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'

let myChart = null

function TranslationStats({ autoload, collapsed }) {
  const controller = new AbortController()

  const [stats, setStats] = useState([])
  const [selectedStat, setSelectedStat] = useState(0)
  const [uniSports, setUniSports] = useState([])
  const [selectedSport, setSelectedSport] = useState({ value: 2, label: 'Basketball' })
  const [selectedLanguage, setSelectedLanguage] = useState({ value: 1, label: 'English' })
  const [languages, setLanguages] = useState([])
  const [visible, setVisible] = useState(collapsed)

  const [message, setMessage] = useState('')

  const sport = async () => {
    return selectedSport
  }

  const language = async () => {
    return selectedLanguage
  }

  const statsHandler = () => {
    let index = stats.findIndex(stat => stat.provider_id == selectedLanguage.value)

    if (index == -1) return
    if (!myChart) return
    setSelectedStat(index)
    myChart.data.datasets.forEach(dataset => {
      dataset.data = [stats[index].sport_stats[selectedSport.label][dataset.label.toLowerCase()].regions, stats[index].sport_stats[selectedSport.label][dataset.label.toLowerCase()].competitions, stats[index].sport_stats[selectedSport.label][dataset.label.toLowerCase()].participants]
    })
    myChart.update()
  }

  const fetchStats = async (data, controller) => {
    setMessage(
      <div className="text-center">
        <i className="fas fa-spinner fa-spin text-center loadingSpinnerColor"></i>
        <span className="loadingSpinnerColor"> Loading Data ...</span>
      </div>
    )

    if (uniSports.length === 0 && !data) {
      let sports_response = await getData('/api/sports', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
      if (sports_response != null) {
        setUniSports(sports_response.data)
        setSelectedSport({ value: sports_response.data[1].id, label: sports_response.data[1].name })
      }
    }

    if (languages.length === 0 && !data) {
      let languages_response = await getConstantData('/api/languages', { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
      if (languages_response != null) {
        setLanguages(languages_response.data)
        setSelectedLanguage({ value: languages_response.data[0].id, label: languages_response.data[0].name })
      }
    }

    let currentLanguage = await language()
    let currentSport = await sport()

    let langParameter = currentLanguage
    let sportparameter = currentSport

    // console.log(data)

    if (data) {
      if (myChart) myChart.destroy()
      langParameter = data.lang ? data.lang : currentLanguage
      sportparameter = data.sport ? data.sport : currentSport
    }

    // console.log(langParameter)
    // console.log(sportparameter)

    if (!langParameter || !sportparameter) return

    let response = await getData(`/api/reports/entities/translated?lang=${langParameter.value}&sport=${sportparameter.value}`, { Authorization: getAuthorizationHeader() }, null, { signal: controller.signal })
    if (response != null && response.data.length > 0) {
      setStats(response.data)
      let sport = sportparameter.label
      let dataSet = [
        {
          label: 'Translated',
          data: [response.data[0].sport_stats[sport].translated.regions, response.data[0].sport_stats[sport].translated.competitions, response.data[0].sport_stats[sport].translated.participants]
        },
        {
          label: 'Untranslated',
          data: [response.data[0].sport_stats[sport].untranslated.regions, response.data[0].sport_stats[sport].untranslated.competitions, response.data[0].sport_stats[sport].untranslated.participants]
        }
      ]

      let statsChart = document.getElementById('translationStatsChart')

      myChart = new Chart(statsChart, {
        type: 'bar',
        data: {
          labels: ['Regions', 'Competitions', 'Participants'],
          datasets: dataSet
        },
        options: {
          plugins: {
            title: {
              display: false,
              text: 'Chart.js Bar Chart - Stacked'
            },
            legend: {
              display: true,
              position: 'top'
            }
          },
          responsive: true,
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      })
    }
    setMessage('')
  }

  const updateStatsSelectedSport = selSport => {
    setSelectedSport(selSport)
    fetchStats({ sport: selSport }, controller)
  }

  const updateStatsSelectedLanguage = selLang => {
    setSelectedLanguage(selLang)
    fetchStats({ lang: selLang }, controller)
  }

  const minimizeChart = e => {
    e.preventDefault()
    if (visible) setVisible(false)
    if (!visible) setVisible(true)

    // if (!myChart) fetchStats(null, controller)
  }

  const makeLegend = (data, items) => {
    if (!data) return
    if (!data.sport_stats[selectedSport.label]) return

    return (
      <Col md="12">
        <Row>
          <Col md="4">
            <Row>
              <Col md="6" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex', paddingLeft: '20%' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data.sport_stats[selectedSport.label].translated.regions_abs}</label>
              </Col>
              <Col md="6" style={{ textAlign: 'left', marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data.sport_stats[selectedSport.label].untranslated.regions_abs}</label>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <Row>
              <Col md="6" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex', paddingLeft: '20%' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data ? data.sport_stats[selectedSport.label].translated.competitions_abs : ''}</label>
              </Col>
              <Col md="6" style={{ textAlign: 'left', marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data ? data.sport_stats[selectedSport.label].untranslated.competitions_abs : ''}</label>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <Row>
              <Col md="6" style={{ textAlign: 'right', marginRight: '0px', paddingRight: '0px', display: 'flex', paddingLeft: '20%' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#9ad0f5', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data ? data.sport_stats[selectedSport.label].translated.participants_abs : ''}</label>
              </Col>
              <Col md="6" style={{ textAlign: 'left', marginLeft: '0px', paddingLeft: '0px', display: 'flex' }}>
                <div style={{ textAlign: 'center', backgroundColor: '#ffb1c1', display: 'block', height: '15px', width: '15px', marginRight: '5px' }}> </div>
                <label style={{ textAlign: 'left', display: 'block', width: '50px' }}>{data ? data.sport_stats[selectedSport.label].untranslated.participants_abs : ''}</label>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )
  }

  useEffect(() => {
    if (autoload) fetchStats(null, controller)

    return () => {
      controller.abort()
      if (myChart) {
        myChart.destroy()
        myChart = null
      }
    }
  }, [])

  useEffect(() => {
    if (myChart && selectedLanguage && selectedSport) statsHandler()
  }, [selectedLanguage, selectedSport])

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h4">
          Entities Translation Stats
          <div style={{ position: 'absolute', top: '5px', right: '10px' }} onClick={e => minimizeChart(e)}>
            <i style={{ display: visible ? '' : 'none' }} className="fas fa-angle-down custom-button-like"></i>
            <i style={{ display: visible ? 'none' : '' }} className="fas fa-angle-up custom-button-like"></i>
          </div>
        </Card.Title>
        <Row style={{ display: visible ? 'none' : '' }}>
          <Col md="12">
            <Row>
              <Col md="6">
                <label>Language:</label>
                <DataSelect data={languages} updateItem={updateStatsSelectedLanguage} selectedItem={selectedLanguage} placeholder={`Select Language...`} />
              </Col>
              <Col md="6">
                <Form.Group>
                  <label>Sport:</label>
                  <SportSelect sports={uniSports} updateSport={updateStatsSelectedSport} selectedItem={JSON.stringify(selectedSport)} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ display: visible ? 'none' : '' }}>
        <div className="stats-chart" id="translationStatsChartHolder" style={{ height: '30rem', marginBottom: '80px', display: message === '' ? '' : 'none' }}>
          <canvas id="translationStatsChart" />
          <Row style={{ margin: '10px 0px 20px 35px' }}>{makeLegend(stats[0], 3)}</Row>
        </div>
        <div>{message}</div>
      </Card.Body>
      <Card.Footer>
        <hr></hr>
        <div className="stats">
          <i className="fas fa-history"></i>
          Updated 3 minutes ago
        </div>
      </Card.Footer>
    </Card>
  )
}

export default TranslationStats
